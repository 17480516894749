import React, { useCallback } from 'react';

import { Radio } from '../radio.component';
import { TermsCheckbox } from '../terms-checkbox.component';
import { useScrollIntoView } from '../../hooks/use-scroll-into-view.hook';

const labels = [
    "Tired",
    "Cough",
    "Runny nose",
    "Difficulty breathing",
    "Recent loss of smell",
    "Headache",
    "Nausea/Vomiting",
    "Diarrhea",
    "Recent loss of taste",
    "Other"
];

export default function QuestionTwo({ values, onChange, t }) {
    if(!values) {
        values = {
            checkedSymptoms: [],
        };
    }

    const scrollToRef = useScrollIntoView();

    const handleRadioChange = useCallback((answer) => {
        onChange({ ...values, answer });
    }, [ onChange, values ]);

    const handleCheckboxChange = useCallback((value) => {
        const checkedSymptoms = [ ...values.checkedSymptoms ];
        const index = checkedSymptoms.indexOf(value);
        if(index !== -1) {
            checkedSymptoms.splice(index, 1);
        } else {
            checkedSymptoms.push(value);
        }
        onChange({ ...values, checkedSymptoms });
    }, [ values, onChange ]);

    const handleOtherSymptomsChange = useCallback((event) => {
        const otherSymptoms = event.target.value;
        onChange({ ...values, otherSymptoms });
    }, [ values, onChange ])

    return (
        <div className="question-panel animateIn" ref={scrollToRef}>
            <p className="t-question t-sans t-grey9">{t("Did you have any symptoms within 2 weeks?")}</p>
            <fieldset id="question2-answer" className="answers">
                <Radio id={'question2-yes'} name="question2-answer" label={t('Yes')} checked={values['answer'] === 'yes'} onChange={() => handleRadioChange('yes')} />
                <Radio id={'question2-no'} name="question2-answer" label={t('No')} checked={values['answer'] === 'no'} onChange={() => handleRadioChange('no')} />
            </fieldset>
            {values['answer'] === 'yes' && (
                <div className="sub-question">
                    <p className="t-question t-sans t-grey9">{t('Please check the symptoms that apply to the following')}</p>
                    <div className="sub-answers sub-answers--checkbox">
                        {labels.map((label, index) => (
                            <TermsCheckbox 
                                key={index} 
                                label={t(label)} 
                                checked={values.checkedSymptoms.indexOf(label) !== -1}
                                onChange={() => handleCheckboxChange(label)}/>
                        ))}
                    </div>
                </div>
            )}
            {values.checkedSymptoms.includes('Other') && (
                <textarea onChange={handleOtherSymptomsChange} className="question-panel__textarea t-sans t-grey9 t-question" />
            )}
        </div>
    )
}
