import React, { useCallback, useRef } from 'react';
import { Radio } from '../radio.component';
import { DateRangePick } from '../daterangepick.component';
import { FormControl } from '../form-control.component';
import { Input } from '../input.component';


export default function QuestionOne({ values, onChange, t }) {
    if(!values) {
        values = {};
    }

    const dateStartRangePickerRef = useRef();
    const dateEndRangePickerRef = useRef();
    

    const handleRadioChange = (answer) => {
        const newValues = { ...values };
        newValues['answer'] = answer;
        onChange(newValues);
    }

    const handleBodytempChange = (e) => {
        
        const newValues = { ...values };
        newValues['bodytemp'] = e.target.value;
        onChange(newValues);
    }

    const handleStartDateRangeChange = useCallback(dates => {
        const newValues = { ...values };
        const [start, end] = dates;
        newValues['startDate'] = start;
        newValues['endDate'] = end;
        onChange(newValues);
        if (end) dateStartRangePickerRef.current.setOpen(false);
    })
    
    const handleEndDateRangeChange = useCallback(dates => {
        const newValues = { ...values };
        const [start, end] = dates;
        newValues['startDate'] = start;
        newValues['endDate'] = end;
        onChange(newValues);
        if (end) dateEndRangePickerRef.current.setOpen(false);
    })

    return (
        <div className="question-panel animateIn">
            <p className="t-question t-sans t-grey9">{t('Did you have a fever within 2 weeks?')}</p>
            <fieldset className="answers" id="question1-answer">
                <Radio id={'question1-yes'} name="question1-answer" checked={values['answer'] === 'yes'} label={t('Yes')} onChange={() => handleRadioChange('yes')} />
                <Radio id={'question1-no'} name="question1-answer" checked={values['answer'] === 'no'} label={t('No')} onChange={() => handleRadioChange('no')} />
            </fieldset>
            {values['answer'] === 'yes' && (
                <div className="sub-question sub-question--bodytemp">
                    <div className="sub-answers sub-answers--daterange">
                        <label className="t-sans t-grey9">{t('Duration')}</label>
                        <div className="date-range-container">
                        <DateRangePick
                            reverse={false}
                            displayPart={'startDate'}
                            dateRangePickerRef={dateStartRangePickerRef}
                            placeholder={('Date (day-month)')} 
                            startDate={values['startDate'] && new Date(values['startDate']) || ''} 
                            endDate={values['endDate'] && new Date(values['endDate']) || ''} 
                            onChange={handleStartDateRangeChange} />                        
                            <p className="spacer t-sans t-grey9">ー</p>
                        <DateRangePick
                            reverse={true}
                            displayPart={'endDate'}
                            dateRangePickerRef={dateEndRangePickerRef}
                            placeholder={('Date (day-month)')} 
                            startDate={values['startDate'] && new Date(values['startDate']) || ''} 
                            endDate={values['endDate'] && new Date(values['endDate']) || ''} 
                            onChange={handleEndDateRangeChange} />
                        </div>
                    </div>
                    <FormControl label={t('Highest Body Temperature')} className="bodytemp" name="bodytemp">
                        <div className="temp-prefix-wrap">
                            <Input name="bodytemp" placeholder="37" value={values['bodytemp'] || ''} onChange={handleBodytempChange} />
                        </div>
                    </FormControl>
                </div>
            )}
        </div>
    )
}