import React from 'react';
import './radio.component.scss';

export const Radio = ({ id, label, ...rest }) => {
    return (
        <div className="radio">
            <input type="radio" id={id} { ...rest } />
            <label className="radio__label t-sans" htmlFor={id}>{label}</label>
        </div>
    );
}