import React, { useCallback} from 'react';
import { useScrollIntoView } from '../../hooks/use-scroll-into-view.hook';

import { Radio } from '../radio.component';
import { TermsCheckbox } from '../terms-checkbox.component';

const labels = [
    "Diabetes",
    "Cancer",
    "High blood pressure",
    "Respiratory diseases",
    "Heart disease",
    "Collagen disease",
    "Chronic kidney disease",
    "Others"
];

export default function QuestionFive({ values, onChange, t }) {
    if(!values) {
        values = {
            checkedIllnesses: [],
        };
    }

    const scrollToRef = useScrollIntoView();

    const handleRadioChange = useCallback((answer) => {
        const newValues = { ...values };
        newValues['answer'] = answer;
        onChange(newValues);
    }, [ onChange, values ]);

    const handleCheckboxChange = useCallback((value) => {
        const checkedIllnesses = [ ...values.checkedIllnesses ];
        const index = checkedIllnesses.indexOf(value);
        if(index !== -1) {
            checkedIllnesses.splice(index, 1);
        } else {
            checkedIllnesses.push(value);
        }
        onChange({ ...values, checkedIllnesses });
    }, [ values, onChange ]);

    const handleOtherSymptomsChange = useCallback((event) => {
        const otherSymptoms = event.target.value;
        const newValues = { ...values };
        newValues.otherSymptoms = otherSymptoms;
        onChange(newValues);
    }, [ values, onChange ])



    return (
        <div className="question-panel animateIn" ref={scrollToRef}>
            <p className="t-question t-sans t-grey9">{t("Do you have any other illnesses or treatement?")}</p>
            <fieldset id="question5-answer" className="answers">
                <Radio id={'question5-yes'} name="question5-answer" label={t('Yes')} checked={values['answer'] === 'yes'} onChange={() => handleRadioChange('yes')} />
                <Radio id={'question5-no'} name="question5-answer" label={t('No')} checked={values['answer'] === 'no'} onChange={() => handleRadioChange('no')} />
            </fieldset>
            {values['answer'] === 'yes' && (
                <div className="sub-question">
                    <p className="t-question t-sans t-grey9">{t('Please check the box that apply to the following')}</p>
                    <div className="sub-answers sub-answers--checkbox sub-answers--checkbox--3">
                        {labels.map((label, index) => (
                            <TermsCheckbox 
                                key={index} 
                                label={t(label)} 
                                checked={values.checkedIllnesses.indexOf(label) !== -1}
                                onChange={() => handleCheckboxChange(label)}/>
                        ))}
                    </div>
                </div>
            )}
            { values['answer'] === 'yes' && values.checkedIllnesses.includes('Others') && (
                <textarea onChange={handleOtherSymptomsChange} value={values['otherSymptoms'] || ''} className="question-panel__textarea t-sans t-grey9 t-question" />
            )}
        </div>
    )
}
