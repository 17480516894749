import React, { useCallback, useState, useEffect } from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';

import { useDispatch } from 'react-redux';
import { FooterNavigation } from '../../components/footer-navigation.component';
import { FormContext } from '../../utils/form.context';
import { saveQuestionnaire } from '../../services/registrations.service';
import { setRegistration } from '../../states/registrations.slice';
import { StepsCompleted, useRegistration } from '../../hooks/use-registration.hook';
import { RegisterLayout } from '../../components/register-layout.component';
import QuestionOne from '../../components/pcr-questionnaire/QuestionOne';
import QuestionTwo from '../../components/pcr-questionnaire/QuestionTwo';
import QuestionThree from '../../components/pcr-questionnaire/QuestionThree';
import QuestionFour from '../../components/pcr-questionnaire/QuestionFour';
import QuestionFive from '../../components/pcr-questionnaire/QuestionFive';


export default function Index() {
    const { t, navigate } = useI18next();

    const registration = useRegistration({ currentStep: StepsCompleted.Pcr.Questionnaire })
    const dispatch = useDispatch();

    const [ errors, setErrors ] = useState(null);
    const [ formValues, setFormValues ] = useState({});
    const [isFinished, setIsFinished] = useState(false);

    const onFinishClick = useCallback(e => {
        saveQuestionnaire({registrationId: registration.id, formValues}).then(response => {
            dispatch(setRegistration(response.data.registration));
            navigate('/test');
        }).catch(error => setErrors(error.response.data.message));

    }, [ formValues, dispatch, navigate ]);

    const handleOnQuestionChange = useCallback((answers, questionName) => {
        const newValues = { ...formValues };
        newValues[questionName] = answers;
        setFormValues(newValues);
    }, [ formValues, setFormValues ]);

    // load saved questionnaire
    useEffect(() => {
        if (registration.questionnaireAnswers) {
        const newFormValues = typeof registration.questionnaireAnswers === 'string' 
            ? JSON.parse(registration.questionnaireAnswers)
            :registration.questionnaireAnswers;
        setFormValues(newFormValues);
        }
    }, [ registration?.questionnaireAnswers ])

    // keep tracking the length of formValues
    useEffect(() => {
        if (Object.keys(formValues).length >= 5) {
            setIsFinished(true)
        }
    }, [formValues])
    
    return <RegisterLayout className="create-account narrow-nav" backLocation="/register/consent">
        <div className="section section--symtoms">
            <h1 className="t-h2 t-grey9">{t('Questionnaire')}</h1>
            <p className="t-sans t-p1 t-grey7 symtoms__body">{t('Please check the following and check the applicable parts.')}</p>
        </div>
        <div className="section section--questionnaire">
            <FormContext.Provider value={errors}>
                <form className="form-questionnaire" onSubmit={onFinishClick}>
                    <QuestionOne t={t} values={formValues.questionOne} onChange={answers => handleOnQuestionChange(answers, 'questionOne')} />
                    { formValues.questionOne && (
                        <QuestionTwo t={t} values={formValues.questionTwo} onChange={answers => handleOnQuestionChange(answers, 'questionTwo')} />
                    )}
                    { formValues.questionTwo && (
                        <QuestionThree t={t} values={formValues.QuestionThree} onChange={answers => handleOnQuestionChange(answers, 'QuestionThree')} />
                    )}
                    { formValues.QuestionThree && (
                        <QuestionFour t={t} values={formValues.QuestionFour} onChange={answers => handleOnQuestionChange(answers, 'QuestionFour')} />
                    )}
                    { formValues.QuestionFour && (
                        <QuestionFive t={t} values={formValues.QuestionFive} onChange={answers => handleOnQuestionChange(answers, 'QuestionFive')} />
                    )}

                </form>
            </FormContext.Provider>
        </div>
        <FooterNavigation 
            steps={5} 
            activeStep={4} 
            onClick={onFinishClick}
            label={t('Finish')} 
            to="/test"
            disabled={!isFinished}
        />
    </RegisterLayout>;
}