import React from 'react';
import DatePicker, { registerLocale } from "react-datepicker";
import { Input } from "./input.component";
import dayjs from 'dayjs';
import 'dayjs/locale/ja'
import ja from 'date-fns/locale/ja';

import "react-datepicker/dist/react-datepicker.css";
import './datepick.component.scss';

registerLocale('ja', ja);

export const DateRangePick = ({ startDate, displayPart, endDate, onChange, dateRangePickerRef, reverse, ...rest }) => {
    const endYear = new Date().getFullYear();
    const years = Array.from({ length: 2 }, (_, i) => endYear - i);

    const months = [
        "一月",
        "二月",
        "三月",
        "四月",
        "五月",
        "六月",
        "七月",
        "八月",
        "九月",
        "十月",
        "十一月",
        "十二月"
    ];

    //mostly from https://reactdatepicker.com/#example-custom-header
    const customHeader = ({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled
    }) => (
        <div
            style={{
                margin: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
            }}
        >
            <button className="datepick-btn" type="button" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                {'<'}
            </button>
            <select
                className="datepick-select"
                value={date.getFullYear()}
                onChange={({ target: { value } }) => changeYear(value)}
            >
                {years.map(option => (
                    <option key={option} value={option}>
                        {option}
                    </option>
                ))}
            </select>
            <select
                className="datepick-select"
                value={months[date.getMonth()]}
                onChange={({ target: { value } }) =>
                    changeMonth(months.indexOf(value))
                }
            >
                {months.map(option => (
                    <option key={option} value={option}>
                        {option}
                    </option>
                ))}
            </select>
            <button className="datepick-btn" type="button" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                {'>'}
            </button>
        </div>
    ); // end of custom header

    const DateRangeInput = React.forwardRef(({ value, onClick, onChange }, ref) => (
        <Input className="date-range" onClick={onClick} readOnly value={value || ''} onChange={onChange} />
    ));

    const displayValue = displayPart === 'startDate' ? startDate && dayjs(startDate).format('MM月D日')
        : endDate && dayjs(endDate).format('MM月D日');
    
    return (<DatePicker
            locale="ja"
            ref={dateRangePickerRef}
            renderCustomHeader={customHeader}
            customInput={<DateRangeInput />}
            selected={startDate}
            onChange={onChange}
            startDate={startDate}
            endDate={endDate}
            popperPlacement={ reverse ? "bottom-end" : "bottom-start"}
            selectsRange
            value={displayValue}
            shouldCloseOnSelect={false}
    />);
};