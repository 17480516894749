import React, { useCallback } from 'react';

import { useScrollIntoView } from '../../hooks/use-scroll-into-view.hook';
import { Radio } from '../radio.component';

const reasons = [
    'Contacted with positive person',
    'Test was required',
    'Overseas travel',
    'Jobs requirement',
    'Others',
];

export default function QuestionThree({ values, onChange, t }) {
    if(!values) {
        values = {};
    }

    const scrollToRef = useScrollIntoView();

    const handleRadioChange = (answer) => {
        const newValues = { ...values };
        newValues['answer'] = answer;
        onChange(newValues);
    }

    const handleOtherReasonsChange = useCallback((event) => {
        const otherReasons = event.target.value;
        const newValues = { ...values };
        newValues.otherReasons = otherReasons;
        onChange(newValues);
    }, [ values, onChange ])

    return (
        <div className="question-panel animateIn" ref={scrollToRef}>
            <p className="t-question t-sans t-grey9">{t('What is the reason for taking this PCR test?')}</p>
            <fieldset className="answers" id="question3-answer">
                {reasons.map((reason, index) => (
                    <Radio key={index} id={`question3-${index}`} name="question3-answer" checked={values['answer'] === reason} label={t(reason)} onChange={() => handleRadioChange(reason)} />
                ))}
            </fieldset>
            {values['answer'] === 'Others' && (
                <textarea onChange={handleOtherReasonsChange} value={values['otherReasons'] || ''} className="question-panel__textarea t-sans t-grey9 t-question" />
            )}
        </div>
    )
}
