import React, { useCallback, useEffect, useRef } from 'react';
import { Radio } from '../radio.component';
import { DatePick } from '../datepick.component';
import { useScrollIntoView } from '../../hooks/use-scroll-into-view.hook';

const currentYear = new Date().getFullYear();
const maxExpirationYears = 10;

const testMethods = [
    "PCR",
    "Antibody",
    "Antigen"
];
const testResults = [
    "Positive",
    "Negative",
    "Waiting Result"
];

export default function QuestionFour({ values, onChange, t }) {
    if(!values) {
        values = {};
    }

    const scrollToRef = useScrollIntoView();

    const handleRadioChange = (answer) => {
        const newValues = { ...values };
        newValues['answer'] = answer;
        onChange(newValues);
    }

    const handleTestMethodChange = useCallback((testmethod) => {
        const newValues = { ...values };
        newValues['testmethod'] = testmethod;
        onChange(newValues);
    }, [onChange, values])

    const handleLastResultChange = useCallback((lastresult) => {
        const newValues = { ...values };
        newValues['lastresult'] = lastresult;
        onChange(newValues);
    }, [onChange, values])

    const handleTestDateChange = useCallback(date => {
        const newValues = { ...values };
        newValues['testDate'] = date;
        onChange(newValues)
    }, [ onChange, values]);

    return (
        <div className="question-panel animateIn" ref={scrollToRef}>
            <p className="t-question t-sans t-grey9">{t('Have you ever had a COVID-19 related test?')}</p>
            <fieldset className="answers" id="question4-answer">
                <Radio id={'question4-yes'} name="question4-answer" checked={values['answer'] === 'yes'} label={t('Yes')} onChange={() => handleRadioChange('yes')} />
                <Radio id={'question4-no'} name="question4-answer" checked={values['answer'] === 'no'} label={t('No')} onChange={() => handleRadioChange('no')} />
            </fieldset>
            {values['answer'] === 'yes' && (
                <div className="sub-question">
                    <p className="t-question t-sans t-grey9">{t('Testing Method')}</p>
                    <fieldset className="sub-answers sub-answers--testmethods" id="question4-testmethods">
                        {testMethods.map((testmethod, index) => (
                            <Radio key={index} id={`question4-testmethod-${index}`} name="question4-testmethods" value={testmethod} checked={values['testmethod'] === testmethod} label={t(testmethod)} onChange={() => handleTestMethodChange(testmethod)} />
                        ))}
                    </fieldset>

                    <p className="t-question t-sans t-grey9">{t('Date')}</p>
                    <div className="sub-answers sub-answers sub-answers--lasttestdate mb-25">
                        <DatePick className="full-width" placeholder={t('Date (day-month)')} startYear={currentYear} endYear={currentYear + maxExpirationYears} value={values['testDate'] && new Date(values['testDate']) || ''} onChange={handleTestDateChange} />
                    </div>

                    <p className="t-question t-sans t-grey9">{t('Testing result')}</p>
                    <fieldset className="sub-answers sub-answers--testresult" id="question4-testresult">
                        {testResults.map((result, index) => (
                            <Radio key={index} id={`question4-testresult-${index}`} name="question4-testresult" checked={values.lastresult === result} label={t(result)} value={result} onChange={() => handleLastResultChange(result)} />
                        ))}
                    </fieldset>
                </div>
            )}
        </div>
    )
}
