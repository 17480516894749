import { useEffect, useRef } from "react";

export const useScrollIntoView = () => {
    const scrollToRef = useRef();

    useEffect(() => {
        scrollToRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }, []);

    return scrollToRef
};
